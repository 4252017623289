/*Mouse Scroller*/
.animated {
    animation-duration: 1.3s;
    animation-fill-mode: both;
}

.animated.infinite {
    animation-iteration-count: infinite;
}

@keyframes slideInDown {
    from {transform: translate3d(0, -50%, 0); visibility: visible; opacity: 0;}
    to {transform: translate3d(0, 0, 0); opacity: 1;}
}

.slideInDown {
    animation-name: slideInDown;
}

.mouse {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -15px;
    z-index: 3;
    animation-duration: 2s;
}

.mouse img {
    cursor: pointer;
}
/*Mouse Scroller*/